import React, { useEffect, useMemo, useState } from 'react';

import { FaExclamationCircle } from 'react-icons/fa';

import {
  Container,
  Attention,
  Gpt,
  Current,
  Steps,
  Access,
  Dear,
  Banner,
} from './styles';
import Player from '~/components/Player';
import thumbFree from '~/assets/defaults/thumb-free.webp';
import quote from '~/assets/icons/quote-pink-thanks.svg';
import logo from '~/assets/logos/logo.svg';
import union1 from '~/assets/defaults/union-1.svg';
import union2 from '~/assets/defaults/union-2.svg';
import Footer from '~/components/Footer';
import DisclaimerNew from '~/components/DisclaimerNew';
import api from '~/services/api';

const PeWelcome: React.FC = () => {
  const [name, setName] = useState<string>();
  const userData = useMemo(() => {
    const data = localStorage.getItem('@AutoAffiliate:userData');
    if (data) {
      return JSON.parse(data);
    }
    return undefined;
  }, []);

  useEffect(() => {
    if (userData !== undefined) {
      api
        .get(`users/admin/${userData.user_id || userData.id}`)
        .then((response) => {
          setName(response.data.name);
        });
    }
  }, [userData, userData?.id, userData?.user_id]);
  return (
    <Container className="posiiton-relative">
      <Attention className="bg-danger">
        <div className="container zoom">
          <div className="row">
            <div className="col zoom">
              <h2 className="text-white mb-0 py-3 text-center">
                <FaExclamationCircle />
                <b className="ms-3">DO NOT CLOSE THIS PAGE YET!: </b>
                Please <b>Read Everything on This Page Carefully</b> to Prevent
                Future Issues With Accessing Your New Account.
              </h2>
            </div>
          </div>
        </div>
      </Attention>
      <div className="position-absolute w-100 px-2 px-md-5">
        <Banner className="position-relative">
          <img src={union1} alt="Union" className="union-1 d-none d-lg-block" />
          <img src={union2} alt="Union" className="union-2 d-none d-lg-block" />
          <svg
            className="cross-top-left"
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            fill="none"
          >
            <path
              d="M7 1a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v2a1 1 0 0 1-1 1H1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v2a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8a1 1 0 0 1 1-1h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H8a1 1 0 0 1-1-1V1z"
              fill="#ada8c4"
            />
          </svg>
          <svg
            className="cross-top-right"
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            fill="none"
          >
            <path
              d="M7 1a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v2a1 1 0 0 1-1 1H1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v2a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8a1 1 0 0 1 1-1h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H8a1 1 0 0 1-1-1V1z"
              fill="#ada8c4"
            />
          </svg>
          <svg
            className="cross-bottom-right"
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            fill="none"
          >
            <path
              d="M7 1a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v2a1 1 0 0 1-1 1H1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v2a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8a1 1 0 0 1 1-1h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H8a1 1 0 0 1-1-1V1z"
              fill="#ada8c4"
            />
          </svg>
          <svg
            className="cross-bottom-left"
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            fill="none"
          >
            <path
              d="M7 1a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v2a1 1 0 0 1-1 1H1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v2a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8a1 1 0 0 1 1-1h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H8a1 1 0 0 1-1-1V1z"
              fill="#ada8c4"
            />
          </svg>
          <div className="d-flex justify-content-center align-items-center ">
            <div className="d-flex justify-content-center align-items-center circle-1 mt-n4">
              <div className="d-flex justify-content-center align-items-center circle-2">
                <div className="d-flex justify-content-center align-items-center circle-3">
                  <div className="d-flex justify-content-center align-items-center circle-4">
                    <div className="d-flex justify-content-center align-items-center circle-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="square-pink" />
          <div className="square-dark-blue" />
          <div className="square-blue" />
          <div className="square-black-1 d-none d-lg-block" />
          <div className="square-black-2 d-none d-lg-block" />
        </Banner>
      </div>
      <Gpt>
        <div className="container zoom">
          <div className="row justify-content-center">
            <div className="col-xxl-10 px-lg-0">
              <h1 className="text-center mt-4 mt-sm-5 pt-4 mb-4">
                🎉 CONGRATULATIONS!! 🎉
                <br />
                <span className="d-block pt-4">
                  <b>Watch the video</b> below for important next step
                  instructions
                </span>
              </h1>
            </div>

            <div className="col-lg-9 mb-4">
              <Player
                id="video"
                src="https://cdn.autoaffiliate.ai/videos/postease-post-purchase-new_v2-pe-welcome.mp4"
                thumbnail={thumbFree}
                autoPlay={!!name}
              />
            </div>
          </div>
        </div>
      </Gpt>

      <Steps>
        <div className="container zoom">
          <div className="row justify-content-center">
            <div className="col-lg-10 mb-4">
              <h2 className="my-sm-5 pb-4 pb-sm-5">
                How to <u>access everything:</u>
              </h2>
              <div className="d-flex align-items-start">
                <h3 className="mb-4 mb-sm-5">
                  Before closing this page please complete the{' '}
                  <span>following steps:</span>
                </h3>
                <img src={quote} alt="Quote" className="quote" />
              </div>
            </div>
            <div className="col-11 col-sm-12 px-sm-0">
              <div className="row">
                <div className="col-lg-6 p-4 p-sm-5 step1 border-gray-left mb-4 mb-lg-0">
                  <h4>1st Step</h4>
                  <hr className="w-50" />
                  <h5 className="py-3">Check your email</h5>
                  <p className="mb-0">
                    Our system has sent you two very important emails by now:
                  </p>
                  <ul className="ms-3 mb-1">
                    <li>
                      The first one, contains{' '}
                      <b className="fw-semibold">your log in and password</b> to
                      access the system.
                    </li>
                    <li>
                      The second, is a welcome email with{' '}
                      <b className="fw-semibold">step-by-step instructions</b>{' '}
                      to get PostEASE up and running fast.
                    </li>
                  </ul>
                  <h6 className="mb-0">
                    Please <u>check spam folder</u> if you do not see them.
                  </h6>
                </div>
                <div className="col-lg-6 p-4 p-sm-5 step2 border-gray-right">
                  <h4>2nd Step</h4>
                  <hr className="w-50" />
                  <h5 className="py-3">Download & Access PostEASE</h5>
                  <p className="mb-3">
                    PostEASE operates through a Chrome extension.{' '}
                    <b className="fw-semibold">
                      To get started, download and install the PostEASE by
                      clicking the button below.
                    </b>{' '}
                    Once installed, log in using the credentials sent to your
                    email.
                  </p>
                  <p>
                    For any questions or assistance, please reach out to our
                    support team through site or{' '}
                    <a href="mailto:support@autoaffiliate.ai">
                      support@autoaffiliate.ai
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Steps>
      <div className="position-relative">
        <div className="circle-large-1" />
        <div className="circle-large-2" />
      </div>
      <Access>
        <div className="container zoom">
          <div className="row justify-content-center">
            <div className="col-lg-11 px-xxl-5">
              <div className="text-center pt-5">
                <h2 className="mt-sm-5">
                  <b>To Access Your PostEASE Download,</b> Click The Button
                  Below
                </h2>
                <a
                  href="https://chromewebstore.google.com/search/PostEASE"
                  className="d-flex justify-content-center align-items-center px-lg-5 mx-auto btn-yes w-75 mt-5"
                >
                  ACCESS YOUR PURCHASE HERE
                </a>
              </div>
            </div>
          </div>
        </div>
      </Access>

      <Dear>
        <img src={logo} alt="Logo" className="logo" />
        <div className="container zoom">
          <div className="row justify-content-center">
            <div className="col-12 p-4 p-sm-5 my-4">
              <h2 className="mb-4 mb-sm-5">Dear {name ?? 'member'},</h2>
              <h3 className="mb-4 mb-sm-5">
                We’re absolutely thrilled to welcome you to PostEASE!{' '}
              </h3>
              <p className="mb-4 mb-sm-5">
                The effort we’ve put into ensuring you can log in right away is
                a testament to our unwavering dedication to your success. We’re
                not just here to guide you; we’re here to partner with you on
                your journey toward tangible achievements.
              </p>

              <p className="mb-4 mb-sm-5">
                Your ambition is now backed by our commitment, and together,
                we’re set to make your goals a reality. Dive into our platform
                and start exploring its features. We’re confident it will exceed
                your vision for what’s possible.
              </p>
              <p className="mb-4 mb-sm-5">
                Additionally, we’d like to introduce you to AutoAffiliate, the
                innovative platform behind PostEASE. AutoAffiliate specializes
                in providing top-tier tools and systems to help entrepreneurs
                and businesses thrive online. As a PostEASE user, you’ll have
                access to a wealth of resources and support from the
                AutoAffiliate team, ensuring your success every step of the way.
              </p>

              <h4>- The AutoAffiliate Team</h4>
            </div>
          </div>
        </div>
      </Dear>
      <div className="position-relative">
        <div className="circle-large-3" />
      </div>
      <Current>
        <div className="container zoom">
          <div className="row justify-content-center">
            <div className="col-sm-11 my-sm-5 py-5 disclaimer">
              <DisclaimerNew />
            </div>
          </div>
        </div>
      </Current>
      <Footer />
    </Container>
  );
};

export default PeWelcome;
